// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import ReactQuill from 'react-quill';

function Footer() {
  const [terms, setTerms] = useState([]);
  const [NewsSection, setNewsSection] = useState([]);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    title: "",
    description: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [selectedImageId, setselectedImageId] = useState(null);
  const [updateImage, setupdateImage] = useState({
  
    title: "",
    description: "",
  })
  const [showUpdateImage, setshowUpdateImage] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [ImageError, setImageError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeleteNavbarAboutPopup, setShowDeleteNavbarAboutPopup] = useState(false);
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(null);

  






  const fetchData = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/publications/list");
      if (response.data && response.data.success) {
        setTerms(response.data.data); // Wrapping the object in an array
        setTermCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };


  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm]);







  const handleUpdate = (term) => {
    setSelectedTermId(term.id);
    setUpdateTerm({
      title: term.title
    });
    setShowUpdateForm(!showUpdateForm);
    };

    const handleTermUpdate = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updateTerm.title.trim()) {
        setUpdateErrorMessage("Title  are required.");
        setTimeout(() => {
          setUpdateErrorMessage("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://back.zubilaw.com/publications/update/${selectedTermId}`,
          updateTerm
        );
    
        if (response.status === 200) {
          console.log("Publications updated successfully:", response.data);
          fetchData();
          setUpdateTerm({ title: ""});
          setSelectedTermId(null);
          setSuccessMessage("Publications updated successfully");
          setUpdateErrorMessage(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating Publications:", error);
        setUpdateErrorMessage("Failed to update Publications. Please try again.");
      }
    };




    const isValidPdfExtension = (fileName) => {
        const validExtensions = ['.pdf']; // Only allow PDF files
        const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
        return validExtensions.includes(`.${fileExtension}`);
      };
      







    const fetchData1 = async () => {
        setLoading(true);
        try {
          // Fetch feature section data
          const response = await axios.get("https://back.zubilaw.com/card/list");
          if (response.data.success) {
            setNewsSection(response.data.data);
            // setInfoBarCount(response.data.data.length);
          } else 
            console.error("Failed to fetch feature sections:", response.data.message);
          
        } catch (error) {
          console.error("Error fetching data:", error);
          setError("Error fetching data. Please try again later.");
        }
        setLoading(false);
      };
      
    
      useEffect(() => {
        fetchData1(); // Call fetchNews when the component mounts
      }, []);




      const handleAddService = async () => {
        // Validate inputs
        const title = document.getElementById('newServiceTitle').value;
        const description = document.getElementById('newServicedescription').value;
        const link = document.getElementById('newServicelink').value;
        const filepdf = document.getElementById('newServiceMainDocument'); // Renamed from mainImageInput
      
        if (!title.trim()) {
          setAddError('Title is required.');
          setTimeout(() => setAddError(''), 5000);
          return;
        }
      
        if (!description.trim()) {
          setAddError('Description is required.'); // Updated text to be consistent
          setTimeout(() => setAddError(''), 5000);
          return;
        }
        // if (!link.trim()) {
        //   setAddError('link is required.'); // Updated text to be consistent
        //   setTimeout(() => setAddError(''), 5000);
        //   return;
        // }
      
        // if (!filepdf.files[0]) {
        //   setAddError('PDF document is required.'); // Updated error message
        //   setTimeout(() => setAddError(''), 5000);
        //   return;
        // } else if (!isValidPdfExtension(filepdf.files[0].name)) {
        //   setAddError('Invalid file extension. Allowed extension is .pdf'); // Updated error message
        //   setTimeout(() => setAddError(''), 5000);
        //   return;
        // }
      
        // If all validations pass
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('link', link);
        formData.append('filepdf', filepdf.files[0]); 
      
        try {
          const response = await axios.post('https://back.zubilaw.com/card/add', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          if (response.status === 200 || response.status === 201) {
            setAddSuccessMessage('Item added successfully'); // Updated success message
            setTimeout(() => setAddSuccessMessage(''), 5000);
            fetchData1(); // Assuming fetchData1 fetches the updated list
          }
        } catch (error) {
          console.error('Error adding item:', error); // Updated log message
          setAddError('Failed to add item. Please try again.'); // Updated error message
          setTimeout(() => setAddError(''), 5000);
        }
      };
      
    
      
    
      
    
      const handleUpdate1 = (event) => {
        if (event.id) {
          // For feature sections
          setselectedImageId(event.id);
          setupdateImage({
            title: event.title,
            description: event.description,
            link: event.link,
            image: event.image // Prepare to update the icon image
          });
          setshowUpdateImage(true);
        } else {
          // For background image
          setNewBackgroundImage(null);
          setShowImageUpdate(true);
        }
      };
    
     
      
      
    
    
      
      const handleEventUpdate = async (e) => {
        e.preventDefault();
        const formData = new FormData();
      
        if (selectedImageId) {
          // Update feature section
          formData.append('title', updateImage.title);
          formData.append('description', updateImage.description);
          formData.append('link', updateImage.link);
          if (updateImage.filepdf && updateImage.filepdf instanceof File) {
            // Check for valid PDF extension
            if (!isValidPdfExtension(updateImage.filepdf.name)) {
              setUpdateError("Invalid file extension. Allowed extensions are .pdf");
              setTimeout(() => setUpdateError(""), 5000);
              return;
            }
            formData.append('filepdf', updateImage.filepdf); // Append the new PDF file
          }
      
          try {
            const response = await axios.put(`https://back.zubilaw.com/card/update/${selectedImageId}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log("Feature section updated successfully:", response.data);
            fetchData1(); // Assuming fetchData1 fetches the feature sections
            setUpdateSuccessMessage("Feature section updated successfully");
            setTimeout(() => setUpdateSuccessMessage(""), 5000);
            setshowUpdateImage(false);
          } catch (error) {
            console.error("Error updating feature section:", error);
            setUpdateError("Error updating feature section");
            setTimeout(() => setUpdateError(""), 5000);
          }
        } else {
            // Update background image
            if (newBackgroundImage) {
                if (!isValidPdfExtension(newBackgroundImage.name)) {
                    setUpdateError("Invalid file extension. Allowed extensions are .pdf");
                    setTimeout(() => setUpdateError(""), 5000);
                    return;
                }
    
                formData.append('image', newBackgroundImage);
    
                try {
                    const response = await axios.put(`https://back.zubilaw.com/card/update/1`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });
    
                    // Fetch the updated background image URL from the server
                    const updatedBgImageResponse = await axios.get("https://back.zubilaw.com/card/list");
                    if (updatedBgImageResponse.data.success) {
                        setBackgroundImage(updatedBgImageResponse.data.data.path);
                    }
    
                    setNewBackgroundImage(null);
                    setShowImageUpdate(false);
                    setUpdateSuccessMessage("Background image updated successfully");
                    setTimeout(() => setUpdateSuccessMessage(""), 5000);
                } catch (error) {
                    console.error("Error updating background image:", error);
                    setUpdateError("Error updating background image");
                    setTimeout(() => setUpdateError(""), 5000);
                }
            } else {
                setUpdateError("Invalid image file extension for background image");
                setTimeout(() => setUpdateError(""), 5000);
            }
        }
    };
    
    
    
    
    const handleDeleteImage = (id) => {
        setselectedImageId(id);
        setShowDeleteNavbarAboutPopup(true);
      };
      
      const performDeleteNavbarAbout = async () => {
        try {
          const response = await axios.delete(`https://back.zubilaw.com/card/delete/${selectedImageId}`);
          if (response.status === 200) {
            const updatedNavbarAboutItems = NewsSection.filter(item => item.id !== selectedImageId);
            setNewsSection(updatedNavbarAboutItems);
            setShowDeleteNavbarAboutPopup(false);
          } else {
            console.error('Failed to delete navbar item');
          }
        } catch (error) {
          console.error('Error deleting navbar item:', error);
        }
      };
    
    
      const modules = {
        toolbar: [
          [{ 'color': [] }, { 'background': [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image', 'video'],
          ['clean']                                         
        ],
      };
      
      const formats = [
        'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
        'list', 'bullet', 'align', 'link', 'image', 'video'
      ];






  return (

    <div className="content">
    
    
    












<div className="content">
  <Row>
    <Col md="12">
     
      <Card>

        <CardBody>

        <Form>
    <Row>
      <Col md="5">
        <FormGroup>
          <label>Title:</label>
          <Input type="text" name="title" id="newServiceTitle" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>description:</label>
          <Input type="text" name="description" id="newServicedescription" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Link:</label>
          <Input type="text" name="link" id="newServicelink" />
        </FormGroup>
      </Col>
      <Col md="8">
      <FormGroup>
        <label>PDF File:</label>
        <Input type="file" name="mainDocument" id="newServiceMainDocument" accept="application/pdf" /> 
      </FormGroup>
    </Col>

      <Col md="12">
        <Button className="btn-round" color="primary" onClick={handleAddService}>
          Add Publications
        </Button>

                      {addSuccessMessage && (
                        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                          {addSuccessMessage}
                        </div>
                      )}
                                       {addError && (
  <div style={{ color: 'red', marginTop: '10px' }}>
    {addError}
  </div>
)}
{ImageError && (
  <div style={{ color: 'red' }}>
    {ImageError}
  </div>
)}


                    </Col>
                  </Row>
                </Form>


          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Link</th>
                <th>File pdf</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {NewsSection.map((news, index) => (
                <tr key={index}>
                  <td>{news.title}</td>
                  <td dangerouslySetInnerHTML={{ __html: news.description }}></td>
                  <td>{news.link}</td>
                  <td>{news.filepdf}</td>
                  <td>
                    <Button color="success" onClick={() => handleUpdate1(news)}>Update</Button>
                    </td>
                    {' '}
                    <td>
                    <Button color="danger" onClick={() => handleDeleteImage(news.id)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        

          {showUpdateImage && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
        <Col md="7">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="title"
              value={updateImage.title}
              
              onChange={(e) => setupdateImage({ ...updateImage, title: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Description:</label>
            <div
        style={{
          height: '300px', // Adjust the height as needed
          overflowY: 'auto', // Add a vertical scrollbar when content overflows
        }}
      >
            <ReactQuill
              value={updateImage.description}
              onChange={(value) =>
                setupdateImage({ ...updateImage, description: value })}
              modules={modules}
              formats={formats}
              style={{ height: '80%', color: 'black' }}
            />
            </div>
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Link:</label>
            <Input
              type="text"
              name="link"
              value={updateImage.link}
              
              onChange={(e) => setupdateImage({ ...updateImage, link: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
        <FormGroup>
         
          <label className="mt-3">Upload New File pdf:</label>
          <Input
            type="file"
            name="filepdf"
            onChange={(e) => setupdateImage({ ...updateImage, filepdf: e.target.files[0] })}
          />
        </FormGroup>
      </Col>
            </Row>

      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Publications
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setshowUpdateImage(false);
                setselectedImageId(null);
                
              }}
            >
              Cancel
            </Button>
            {updateErrorMessage && (
    <div style={{ color: 'red', marginTop: '10px' }}>
        {updateErrorMessage}
    </div>
)}

            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

        </CardBody>
      </Card>
    </Col>
  </Row>
</div>




<Modal isOpen={showDeleteNavbarAboutPopup} toggle={() => setShowDeleteNavbarAboutPopup(false)}>
<ModalHeader toggle={() => setShowDeleteNavbarAboutPopup(false)}>Confirm Delete</ModalHeader>
<ModalBody>
Are you sure you want to delete this navbar item?
</ModalBody>
<ModalFooter>
<Button color="danger" onClick={performDeleteNavbarAbout}>Delete</Button>{' '}
<Button color="secondary" onClick={() => setShowDeleteNavbarAboutPopup(false)}>Cancel</Button>
</ModalFooter>
</Modal>
</div>


);
}

export default Footer;    